import React from 'react'

import PageWrapper from '../components/PageWrapper'
import Hero from '../sections/common/Hero'
import Content from '../sections/brand/Content'
import { Helmet } from 'react-helmet'

const Brand = () => {
  return (
    <>
      <Helmet>
        <title>Brand Assets | Alkemi Network</title>
        <meta name="description" content="Download Alkemi Network's brand assets & brandguide"/>
        <meta property="og:title" content="Brand Assets | Alkemi Network" />
        <meta property="og:description" content="Download Alkemi Network's brand assets & brandguide" />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:url" content="https://alkemi.network/brand-assets/" />
        <link rel="canonical" href="https://alkemi.network/brand-assets/" />
        </Helmet>
      <PageWrapper footerDark>
        <Hero title="Brand Assets">
          Download the official Alkemi logos & branding assets from this page to use in articles or other media.
        </Hero>
        <Content />
      </PageWrapper>
    </>
  )
}
export default Brand
