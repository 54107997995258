import React from 'react'
import styled from 'styled-components'
import { Col, Container, Row } from 'react-bootstrap'

import { Box, Section, Span } from '../../components/Core'

import logo from '../../assets/image/svg/alkemi-logo.svg'
import logoIcon from '../../assets/image/svg/alkemi-icon.svg'
import logoWhite from '../../assets/image/svg/alkemi-logo-white.svg'
import logoIconWhite from '../../assets/image/svg/alkemi-icon-white.svg'


const Asset = styled(Box)`
  display: flex;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  border-radius: 6px;
  `
  const AssetIcon = styled(Box)`
    display: flex;
    justify-content: center;
    /* align horizontal */
    align-items: center;
    border-radius: 6px;
    `

const Content = () => (
  <>
    {/* <!-- Brand Assets --> */}
    <Section
      className="position-relative"
      pt="140px !important"
      pb="140px !important"
    >
      <Box pb={'10px'}>
        <Container display="flex" >
        <Row>
          <Col lg="6">
          <Asset className="" py={4} mb={3} backgroundColor="primary" border="solid 1px #4343FF" >
              <img src={logoWhite} alt="" Height="80px" />
          </Asset>
          </Col>
          <Col lg="2" >
          <AssetIcon className="" py={4} mb={3} backgroundColor="primary" border="solid 1px #4343FF">
              <img src={logoIconWhite} alt="" Height="80px" />
          </AssetIcon>
          </Col>
          </Row>
        <Row>
          <Col lg="6">
          <Asset className="" py={4} mb={3} border="solid 1px #000000" >
              <img src={logo} alt="" Height="80px" />
          </Asset>
          </Col>
          <Col lg="2" >
          <AssetIcon className="" py={4} mb={3} border="solid 1px #000000" Width="">
              <img src={logoIcon} alt="" Height="80px" />
          </AssetIcon>
          </Col>
          </Row>
            <Col lg="8">
            <Row>
              <Box mt={3}><a href="../../alkemi-brand-assets.zip" download target="_blank" rel="noreferrer">
              <Span color="primary"mr={3} p={2} backgroundColor="#f6f6ff" borderRadius="6px">
                    <i className="fas fa-file-download mr-2"></i>
                    Download brand-assets.zip</Span>
                        </a>
               </Box>
              <Box  mt={3}>
               <a href="../../alkemi-brand-guidelines.pdf" download target="_blank" rel="noreferrer">
              <Span color="primary" p={2} backgroundColor="#f6f6ff" borderRadius="6px">
                    <i className="fas fa-file-pdf mr-2"></i>
                    Download brand-guidelines.zip</Span>
                    </a>
              </Box>
              </Row>
            </Col>
        </Container>
      </Box>
    </Section>
  </>
)

export default Content
